// Base Imports
import { useEffect } from 'react'
import type { GetStaticPropsContext } from 'next'

// Other Imports
import commerce from '@lib/api/commerce'
import {
  CURRENT_HOME_COMPONENT_PAGE,
  HOMEPAGE_SLUG,
  HOME_PAGE_DEFAULT_SLUG,
  THANK_YOU_DEFAULT_SLUG,
} from '@components/utils/constants'
import withDataLayer, { PAGE_TYPES } from '@components/withDataLayer'
import { EVENTS_MAP } from '@components/services/analytics/constants'
import useAnalytics from '@components/services/analytics/useAnalytics'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { LayoutWithTopStrip } from '@components/common/Layout'
import CategoryRoundWidgetSkeleton from '@components/utils/skeleton/categoryRoundWidget.skeleton'
import { useUI } from '@components/ui'
import { extractNumberFromHTMLpTag } from '@framework/utils/app-util'
import Script from 'next/script'
import { IS_SWOP_STORE_ENABLED } from '@framework/utils/constants'
import dynamic from 'next/dynamic'
import { getComponents, pageView } from '@components/home/helper'
import { getItem } from '@components/utils/localStorage'
import { LocalStorage } from '@components/utils/payment-constants'
import {
  getDataByUID,
  parseDataValue,
  setData,
  containsArrayData,
} from '@framework/utils/redis-util'
import { Redis } from '@framework/utils/redis-constants'
import Cookies from 'js-cookie'
import { HomePageService } from 'services/homePage/index.service'
import ProductListStickyBar from '@components/men/ProductListStickyBar'
import { isMobileOnly } from 'react-device-detect'
import { encrypt } from '@framework/utils/cipher'
import { IPagePropsProvider } from '@framework/contracts/page-props/IPagePropsProvider'
import { PagePropType, getPagePropType } from '@framework/page-props'
import useDarkMode from 'hooks/useDarkMode'
import useEventAnalytics from '@components/services/analytics/useEventAnalytics'
import { AnalyticsEventType } from '@components/services/analytics'

const TopCategory = dynamic(
  () => import('@components/home/CategoryRoundWidget'),
  { ssr: false }
)
const HeroBanner = dynamic(() => import('@components/ui/Hero'), { ssr: false })
const FavouritePayments = dynamic(
  () => import('@components/home/FavouritePayments'),
  { ssr: false }
)
const FavouritePaymentsData = dynamic(
  () => import('@components/home/FavouritePaymentsData'),
  { ssr: false }
)
const BestSellerProducts = dynamic(
  () => import('@components/home/BannerBackground'),
  { ssr: false }
)
const BestSellerTitle = dynamic(
  () => import('@components/home/BestSellerTitle'),
  { ssr: false }
)
const CategoryList = dynamic(() => import('@components/home/BannerCategory'), {
  ssr: false,
})
const CategoryListData = dynamic(
  () => import('@components/home/BannerCategoryData'),
  { ssr: false }
)
const MidBannerTop = dynamic(
  () => import('@components/home/CategoryColumnPanel'),
  { ssr: false }
)
const MidBannerTopData = dynamic(
  () => import('@components/home/CategoryColumnPanelData'),
  { ssr: false }
)
const Loader = dynamic(() => import('@components/ui/LoadingDots'), {
  ssr: false,
})
// const CountdownSale = dynamic(() => import('@components/home/Countdown'));
const ColumnImage = dynamic(() => import('@components/home/ColumnImage'), {
  ssr: false,
})
const RichTextContent = dynamic(
  () => import('@components/home/RichTextContent'),
  { ssr: false }
)

export async function getStaticProps({
  preview,
  locale,
  locales,
}: GetStaticPropsContext) {
  const config = { locale, locales }
  const cachedDataUID = {
    infraUID: Redis.Key.INFRA_CONFIG,
    pageContentWebUID: Redis.Key.Homepage.PageContentWeb,
    pageContentMobileWebUID: Redis.Key.Homepage.PageContentMobileWeb,
    pageContentSlugUID: Redis.Key.Homepage.PageContentSlug,
    pagesUID: Redis.Key.Homepage.Pages,
    siteInfoUID: Redis.Key.Homepage.SiteInfo,
    swopStorePageUID: Redis.Key.SwopStorePage + '_' + THANK_YOU_DEFAULT_SLUG,
  }
  const cachedData = await getDataByUID([
    cachedDataUID.infraUID,
    cachedDataUID.pageContentWebUID,
    cachedDataUID.pageContentMobileWebUID,
    cachedDataUID.pageContentSlugUID,
    cachedDataUID.pagesUID,
    cachedDataUID.siteInfoUID,
    cachedDataUID.swopStorePageUID,
  ])
  let infraUIDData = parseDataValue(cachedData, cachedDataUID.infraUID)
  let pageContentSlugUIDData = parseDataValue(
    cachedData,
    cachedDataUID.pageContentSlugUID
  )
  let pagesUIDData = parseDataValue(cachedData, cachedDataUID.pagesUID)
  let siteInfoUIDData = parseDataValue(cachedData, cachedDataUID.siteInfoUID)
  let pageContentWebUIDData = parseDataValue(
    cachedData,
    cachedDataUID.pageContentWebUID
  )
  let pageContentMobileWebUIDData = parseDataValue(
    cachedData,
    cachedDataUID.pageContentMobileWebUID
  )
  let swopStorePageData: any = parseDataValue(
    cachedData,
    cachedDataUID.swopStorePageUID
  )

  if (!infraUIDData) {
    infraUIDData = await commerce.getInfra()
    await setData([{ key: cachedDataUID.infraUID, value: infraUIDData }])
  }

  if (!pageContentSlugUIDData) {
    pageContentSlugUIDData = await commerce.getSlugs({ slug: HOMEPAGE_SLUG })
    await setData([
      { key: cachedDataUID.pageContentSlugUID, value: pageContentSlugUIDData },
    ])
  }

  if (!containsArrayData(pagesUIDData)) {
    const { pages } = await commerce.getAllPages({ config, preview })
    pagesUIDData = pages
    await setData([{ key: cachedDataUID.pagesUID, value: pagesUIDData }])
  }

  if (!siteInfoUIDData) {
    siteInfoUIDData = await commerce.getSiteInfo({ config, preview })
    await setData([{ key: cachedDataUID.siteInfoUID, value: siteInfoUIDData }])
  }

  if (!containsArrayData(pageContentWebUIDData)) {
    const res = await HomePageService.retrievePageContent({
      id: '44024ddf-7819-4511-9f76-ab6ca17c3e66', //pageId,
      slug: HOME_PAGE_DEFAULT_SLUG,
      workingVersion: process.env.NODE_ENV === 'production' ? true : true, // TRUE for preview, FALSE for prod.
      channel: 'Web',
    })
    pageContentWebUIDData = res?.data ?? {}
    await setData([
      { key: cachedDataUID.pageContentWebUID, value: pageContentWebUIDData },
    ])
  }

  if (!containsArrayData(pageContentMobileWebUIDData)) {
    const res = await HomePageService.retrievePageContent({
      id: '44024ddf-7819-4511-9f76-ab6ca17c3e66', //pageId,
      slug: HOME_PAGE_DEFAULT_SLUG,
      workingVersion: process.env.NODE_ENV === 'production' ? true : true, // TRUE for preview, FALSE for prod.
      channel: 'MobileWeb',
    })
    pageContentMobileWebUIDData = res?.data ?? {}
    await setData([
      {
        key: cachedDataUID.pageContentMobileWebUID,
        value: pageContentMobileWebUIDData,
      },
    ])
  }

  if (!swopStorePageData) {
    const SwopStorePageContentsPromise = HomePageService.retrievePageContent({
      id: '', //pageId,
      slug: THANK_YOU_DEFAULT_SLUG, //slugs?.slug,
      workingVersion: process.env.NODE_ENV === 'production' ? true : true, // TRUE for preview, FALSE for prod.
      channel: '',
    })
    const res = await SwopStorePageContentsPromise
    swopStorePageData = res?.data ?? {}
    await setData([
      { key: cachedDataUID.swopStorePageUID, value: swopStorePageData },
    ])
  }

  const props: IPagePropsProvider = getPagePropType({ type: PagePropType.COMMON }) as IPagePropsProvider
  const commonPageProps = await props.getPageProps({ cookies: {} })
  return {
    props: {
      ...commonPageProps,
      categories: siteInfoUIDData?.categories ?? [],
      brands: siteInfoUIDData?.brands ?? [],
      pages: pagesUIDData ?? [],
      slugs: pageContentSlugUIDData ?? {},
      globalSnippets: infraUIDData?.snippets ?? [],
      snippets: pageContentSlugUIDData?.snippets ?? [],
      pageContentsWeb: pageContentWebUIDData ?? {},
      pageContentsMobileWeb: pageContentMobileWebUIDData ?? {},
      swopStorePageContents: swopStorePageData ?? {},
    },
    revalidate: 60,
  }
}

const PAGE_TYPE = PAGE_TYPES.Home

function Home({
  slugs,
  pageContentsWeb,
  pageContentsMobileWeb,
  deviceInfo,
  swopStorePageContents,
  previousPath,
}: any) {
  const { PageViewed } = EVENTS_MAP.EVENT_TYPES
  const { isMobile, isIPadorTablet } = deviceInfo
  const darkModeCSS = useDarkMode(pageContentsWeb?.darkmode)
  const { user } = useUI()
  const { recordAnalytics } = useEventAnalytics()

  let hostName = ''
  if (typeof window !== 'undefined') {
    hostName = window?.location?.hostname ?? ''
  }

  const pageContents =
    typeof isMobile !== 'undefined' || typeof isIPadorTablet !== 'undefined'
      ? isMobile || isIPadorTablet
        ? {
          ...pageContentsMobileWeb,
          colvideoview: 'mob',
        }
        : {
          ...pageContentsWeb,
          colvideoview: 'desktop',
        }
      : null

  const showPadding = pageContents?.hidepadding
    ? pageContents?.hidepadding?.toLowerCase() === 'true'
      ? false
      : true
    : true

  useAnalytics(PageViewed, {
    entity: JSON.stringify({
      id: slugs?.id,
      name: slugs?.name,
      metaTitle: slugs?.metaTitle,
      MetaKeywords: slugs?.metaKeywords,
      MetaDescription: slugs?.metaDescription,
      Slug: slugs?.slug,
      Title: slugs?.title,
      ViewType: slugs?.viewType,
    }),
    entityName: PAGE_TYPE,
    pageTitle: slugs?.title,
    entityType: 'Page',
    entityId: slugs?.id,
    eventType: 'PageViewed',
  })
  const router = useRouter()

  useEffect(() => {
    pageView(user)
    if (!!getItem(LocalStorage.Key.IS_OFFLINE_STORE)) {
      Cookies.set(LocalStorage.Key.IS_OFFLINE_STORE, 'false')
    }
  }, [user])

  const getSrcForSwopStoreScript = () => {
    const str = swopStorePageContents?.script1?.toString()
    const srcRegex =
      /<script\s+src="([^"]+)"\s+type="text\/javascript"><\/script>/
    const match = str?.match(srcRegex)
    const srcValue = match ? match[1] : null
    return srcValue?.replaceAll(
      'SHOP_ID',
      extractNumberFromHTMLpTag(swopStorePageContents?.shopid)
    )
  }

  const generateComponents = (key: any) => {
    return getComponents(
      key,
      pageContents,
      deviceInfo,
      showPadding,
      CURRENT_HOME_COMPONENT_PAGE.HOME
    )
  }

  const renderTopCategories = () => {
    if (
      typeof pageContents?.['topcategory'] === 'undefined' &&
      typeof pageContents?.['topcategory22'] === 'undefined' &&
      typeof pageContents?.['topcategory2'] === 'undefined'
    )
      return <CategoryRoundWidgetSkeleton />

    if (!!pageContents?.['topcategory']) {
      return (
        <div className="min-h-[155px]">
          <TopCategory data={pageContents['topcategory']} />
        </div>
      )
    }

    if (!!pageContents?.['topcategory22']) {
      return (
        <div className="min-h-[155px]">
          <TopCategory data={pageContents['topcategory22']} />
        </div>
      )
    }

    if (!!pageContents?.['topcategory2']) {
      return (
        <div className="min-h-[155px]">
          <TopCategory data={pageContents['topcategory2']} />
        </div>
      )
    }
  }
  useEffect(()=>{
    recordAnalytics(AnalyticsEventType.HOME_PAGE, { type: 'home_page', data: {} })
  },[])
  return (
    <div className={darkModeCSS}>
      {(pageContents?.metatitle ||
        pageContents?.metadescription ||
        pageContents?.metakeywords) && (
          <>
            <NextHead>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
              <link
                rel="canonical"
                id="canonical"
                href={
                  pageContents?.canonical ||
                  'https://www.damensch.com' + router.asPath
                }
              />
              {pageContents?.metatitle && (
                <>
                  <title>{pageContents?.metatitle}</title>
                  <meta name="title" content={pageContents?.metatitle} />
                </>
              )}

              {pageContents?.metadescription && (
                <meta
                  name="description"
                  content={pageContents?.metadescription}
                />
              )}

              {pageContents?.metakeywords && (
                <meta name="keywords" content={pageContents?.metakeywords} />
              )}
              <meta property="og:image" content={pageContents?.image} />
              {pageContents?.metatitle && (
                <meta
                  property="og:title"
                  content={pageContents?.metatitle}
                  key="ogtitle"
                />
              )}
              {pageContents?.metadescription && (
                <meta
                  property="og:description"
                  content={pageContents?.metadescription}
                  key="ogdesc"
                />
              )}
            </NextHead>
          </>
        )}
      <h1 className="hidden">Trending Men's Fashion by Damensch</h1>
      <h2 className="hidden">Damensch Best sellers collection</h2>
      <h3 className="hidden">Latest combo packs</h3>
      <h4 className="hidden">New collection by Damensch</h4>
      <h5 className="hidden">Shop from amazing collection</h5>
      {hostName && <input className="inst" type="hidden" value={hostName} />}

      {/* {generateComponents()} */}

      <div className="mobile-tab">{renderTopCategories()}</div>
      {!pageContents ? (
        <>
          <div className="flex w-full text-center flex-con">
            <Loader />
          </div>
        </>
      ) : (
        Object?.keys(pageContents)?.map((key: any) => {
          return generateComponents(key)
        })
      )}
      {IS_SWOP_STORE_ENABLED &&
        swopStorePageContents?.shopid &&
        swopStorePageContents?.script1 && (
          <Script
            id="swopStore"
            strategy="lazyOnload"
            src={getSrcForSwopStoreScript()}
          />
        )}
      <div className="mobile-tab ">
        <ProductListStickyBar
          elementTagId={`home-banner-swiper`}
          deviceInfo={deviceInfo}
          previousPath={previousPath}
        />
      </div>
    </div>
  )
}

Home.Layout = LayoutWithTopStrip

export default withDataLayer(Home, PAGE_TYPE, true, LayoutWithTopStrip)
